import { styled } from '@mui/material/styles'
import ImageA11ySection from 'common/formControls/ImageA11ySection'
import HFAssetUploader from 'common/formControls/selects/AssetUploader/HFAssetUploader'
import { componentShape } from 'core/shapes'
import { when } from 'fp/utils'
import Caption from 'hss/ContentBuilder/interactives/Form/Caption'
import { formPropsShape } from 'hss/ContentBuilder/utils'
import PropTypes from 'prop-types'
import { useEffect } from 'react'
import { useFormContext } from 'react-hook-form'
import { useSelector } from 'react-redux'
import { getParentContentIsTabbed } from 'selectors/contentViewer'

const Section = styled('div', { name: 'Curriculum-ImageControls' })(
  ({
    theme: {
      mixins: { /* borderS, */ em },
      palette,
      typography: { variants },
    },
  }) => ({
    // ...borderS(palette.border[1]),
    // ...borderS('red', 2),
    border: `1px solid ${palette.border[1]}`,
    borderRadius: em(0.5),
    marginTop: em(2),
    padding: em(1.5),
    paddingTop: 0,
    '.MuiAccordionSummary-content': {
      ...variants['list-header'],
      fontWeight: 400,
    },
  }),
)

const ImageControls = props => {
  const {
    captionPosition,
    formProps: { content },
    full = true,
    label = 'Image',
    labelProps,
    showMetaData = false,
    thumb = true,
  } = props
  const { contentType, id } = content || {}
  const tabbed = useSelector(
    getParentContentIsTabbed({ contentType, contentId: id }),
  )

  const { setValue } = useFormContext()

  // if a captionPosition is passed in, then it can't be changed and doesn't need to be shown
  const showCaptionPosition = !captionPosition

  useEffect(() => {
    when(captionPosition, setValue, 'captionPosition', captionPosition)
  }, [captionPosition, setValue])

  if (tabbed) {
    return null
  }

  return (
    <>
      {Boolean(full) && (
        <Section>
          <HFAssetUploader
            label={label}
            labelProps={labelProps}
            name="uploadsMap.image"
            uploadType="image"
            variantLinkage={
              thumb
                ? [
                    {
                      formField: 'uploadsMap.imageThumb',
                      variant: 'thumb',
                    },
                  ]
                : null
            }
          />
          {Boolean(showMetaData) && (
            <>
              <ImageA11ySection altTextProps={{ required: false }} />
              <Caption showCaptionPosition={showCaptionPosition} />
            </>
          )}
        </Section>
      )}

      {Boolean(thumb) && (
        <Section>
          <HFAssetUploader
            label="Thumbnail"
            labelProps={labelProps}
            name="uploadsMap.imageThumb"
            uploadType="image"
            uploadVariant="thumb"
          />
        </Section>
      )}
    </>
  )
}

ImageControls.propTypes = {
  captionPosition: PropTypes.string,
  formProps: formPropsShape.isRequired,
  label: componentShape,
  labelProps: PropTypes.object,
  full: PropTypes.bool,
  showMetaData: PropTypes.bool,
  thumb: PropTypes.bool,
}

export default ImageControls
