import RichTextEdit from 'common/formControls/textInputs/HFRichTextEdit'
import PropTypes from 'prop-types'

export const allFeatures = {
  funFacts: true,
  'headlines.dual': true,
  interactives: true,
  'links.advanced': true,
  'lists.card': true,
  'lists.sourceHeader': true,
  'lists.unstyled': true,
  tables: true,
  'typography.answer': true,
  'typography.advancedHeader': true,
  'typography.contentHeader': true,
  'typography.credit': true,
  'typography.dropCap': true,
  'typography.featuredParagraph': true,
  'typography.format': true,
  'typography.highlight': true,
  'typography.numberCrunch': true,
  'typography.sourceTitle': true,
  'typography.subHeading': true,
}

const EnhancedRichTextEdit = props => {
  const { contentBlockVariant, features = allFeatures, ...rest } = props

  return (
    <RichTextEdit
      {...{
        ...rest,
        features,
        excludeToolButtons: [
          // We use custom headers here
          'header-one',
          'header-two',
          'header-three',
          ...(rest.excludeToolButtons || []),
        ],
      }}
      showBlockInsight
    />
  )
}

EnhancedRichTextEdit.propTypes = {
  contentBlockVariant: PropTypes.string,
  features: PropTypes.object,
}

export default EnhancedRichTextEdit
